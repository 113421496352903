// TaskScheduler.tsx
import {addDays} from "date-fns";
import {DayPicker} from "../../../../components/blocks/DatePicker";
import HourPicker from "../../../../components/primitives/HourPicker/HourPicker";
import {Button} from "../../../../components/primitives/Button";
import {Check} from "../../../../components/primitives/icons";
import {convertToStringHour} from "../../../../../utils/convertToStringHour";
import {Task} from "../../../../data-access/programmed-task";
import {Dispatch, SetStateAction} from "react";

interface TaskSchedulerProps {
	programmedTaskDate: Date;
	setScheduledTaskValue: Dispatch<
		SetStateAction<{
			date: string;
			hour: string;
		}>
	>;
	scheduledTaskValue: {date: string; hour: string};
	serieProgrammedTask?: Task;
	disableProgramTask: boolean;
	programTask: () => void;
}

const TaskScheduler = ({
	programmedTaskDate,
	scheduledTaskValue,
	serieProgrammedTask,
	disableProgramTask,
	setScheduledTaskValue,
	programTask,
}: TaskSchedulerProps) => {
	return (
		<div className="flex">
			<DayPicker
				selectedDay={programmedTaskDate}
				onDayChange={date => setScheduledTaskValue(prev => ({...prev, date: date.toISOString()}))}
				className="min-w-[150px] justify-start self-start"
				disabled={{before: new Date(), after: addDays(new Date(), 30)}}
			/>
			<p className="mx-2 self-center text-xl">-</p>
			<HourPicker
				hourSelected={
					scheduledTaskValue.hour
						? convertToStringHour(`${scheduledTaskValue.date}T${scheduledTaskValue.hour}`)
						: serieProgrammedTask
						? convertToStringHour(serieProgrammedTask.dateObject)
						: convertToStringHour(null)
				}
				onTimeChange={date => setScheduledTaskValue(prev => ({...prev, hour: date}))}
				className="w-fit"
			/>
			{!disableProgramTask && (
				<Button type="button" onClick={programTask} className="ml-2 w-auto min-w-fit gap-2 self-start">
					<Check />
					Programar tarea
				</Button>
			)}
		</div>
	);
};

export default TaskScheduler;
