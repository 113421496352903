import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

interface GetUserRentalListResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: Rental[];
}

export interface GetUserRentalListParams {
	page_size?: number;
	page?: number;
	userid: string;
}

export interface Rental {
	active: boolean;
	created_at: string;
	first_use_date: string | null;
	termination_date: string;
	seriesid: string;
	rental_type: number;
	rental_name: string;
	rental_description: string;
	series_title: string;
	series_description: string;
	img_banner_web_mobile: string;
	img_banner_web_desktop: string;
}

export async function getUserRentals(token: string, params: GetUserRentalListParams): Promise<AxiosResponse<GetUserRentalListResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_series_rentals", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}
