import {Link, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {useEffect, useMemo, useState} from "react";
import {selectedRentalSerieSelector, seriesActions} from "../../../../_store/features/series/series-slice";
import {associatesActions} from "../../../../_store/features/associates/associates-slice";
import {auth} from "../../../../firebase";
import {Pencil, Spinner} from "../../../../components/primitives/icons";
import {Button} from "../../../../components/primitives/Button";
import {SelectOptionType} from "../../../../../utils/globalTypes";
import {Transition} from "@headlessui/react";
import {getUserRentals, Rental} from "../../../../data-access/series/user-rentals";
import {createColumnHelper} from "@tanstack/react-table";
import {DataTable} from "../../../../components/blocks/DataTable";
import {DataTableColumnHeader} from "../../../../components/primitives/DataTable";
import {Badge} from "../../../../components/primitives/Badge";
import {formattedDayAndHour} from "../../../../../utils/formattedDayAndHour";

const StatusEnum = {
	LOADING: "Loading",
	IDLE: "Idle",
	ADDING_RENTAL: "AddingRental",
	MODIFY_RENTAL: "ModifyRental",
} as const;

type Status = (typeof StatusEnum)[keyof typeof StatusEnum];

const columnHelper = createColumnHelper<Rental>();

const Rentals = () => {
	const {id} = useParams();
	const series = useAppSelector(selectedRentalSerieSelector);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const [status, setStatus] = useState<Status>(StatusEnum.LOADING);
	const [currentUserRentalList, setCurrentUserRentalList] = useState<Rental[]>([]);

	const listAvailableRentals: SelectOptionType[] = useMemo(() => {
		if (!series || currentUserRentalList.length === series.length) return [];

		const selectedSeriesIds = currentUserRentalList.map(rental => rental.seriesid);
		return series
			.filter(serie => !selectedSeriesIds.includes(serie.id))
			.map(serie => ({
				label: `${serie.title}`,
				value: serie.id,
			}));
	}, [currentUserRentalList, series]);

	const dispatch = useAppDispatch();

	const columns = useMemo(() => {
		let tableCellWidth = (tableRef?.getBoundingClientRect().width ?? 0) / 6.0;

		return [
			columnHelper.accessor("rental_name", {
				id: "Nombre de la renta",
				header: ({column}) => <DataTableColumnHeader title="Nombre de la renta" column={column} />,
				cell: info => <div className="ml-2 flex w-full text-left">{info.getValue()}</div>,
				size: tableCellWidth,
				enableResizing: false,
				enableSorting: false,
			}),
			columnHelper.accessor("created_at", {
				id: "Creado",
				header: ({column}) => <DataTableColumnHeader title="Creado" column={column} />,
				cell: info => {
					const formattedTime = formattedDayAndHour(info.row.original.created_at);

					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedTime}</span>
						</div>
					);
				},
				size: tableCellWidth,
			}),
			columnHelper.accessor("first_use_date", {
				id: "Primer Uso",
				header: ({column}) => <DataTableColumnHeader title="Primer Uso" column={column} />,
				cell: info => {
					if (!info.row.original.first_use_date) return "No ha sido usado aún";
					const formattedTime = formattedDayAndHour(info.row.original.first_use_date);

					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedTime}</span>
						</div>
					);
				},
				size: tableCellWidth,
			}),
			columnHelper.accessor("termination_date", {
				id: "Fecha de terminación",
				header: ({column}) => <DataTableColumnHeader title="Fecha de terminación" column={column} />,
				cell: info => {
					const formattedTime = formattedDayAndHour(info.row.original.termination_date);

					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedTime}</span>
						</div>
					);
				},
				size: tableCellWidth,
			}),
			columnHelper.accessor("active", {
				id: "Activo",
				header: ({column}) => <DataTableColumnHeader title="Activo" column={column} />,
				cell: info => (
					<div className="flex w-full">
						{info.getValue() ? (
							<Badge variant={"success"} className="ml-2 flex max-h-[20px] text-left">
								Activo
							</Badge>
						) : (
							<Badge variant={"destructive"} className="ml-2 flex max-h-[20px] text-left">
								Inactivo
							</Badge>
						)}
					</div>
				),
				size: tableCellWidth,
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="justify-left flex w-full gap-2">
						<Button size={"sm"} variant={"outline"} asChild>
							<Link to="#">
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>
					</div>
				),
				size: tableCellWidth,
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	useEffect(() => {
		if (!id) return;
		setStatus(StatusEnum.LOADING);
		dispatch(
			seriesActions.getSeriesList({
				params: {page_size: 9999},
			}),
		);
		dispatch(associatesActions.getUserAssociatesSeries({page_size: 99999}));

		auth.currentUser
			?.getIdToken()
			.then(token => {
				getUserRentals(token, {userid: id!, page_size: 9999})
					.then(res => {
						setCurrentUserRentalList(res.data.results);
						setStatus(StatusEnum.IDLE);
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => console.log(err));
	}, [dispatch, id]);

	const isLoading = status === StatusEnum.LOADING;

	return (
		<>
			<div className="px-4 py-5 sm:px-6">
				<h2 className="text-base font-semibold leading-6 text-gray-900">Detalles de Rentas</h2>
				<p className="mt-1 max-w-2xl text-sm text-gray-500">Actualiza las rentas de los usuarios y agrega nuevas.</p>
			</div>

			{/* <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
				<h3 className="text-base font-semibold leading-6 text-gray-900">Añadir nueva renta:</h3>
				<div className="mt-1 items-center text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
					{isLoading ? (
						<Spinner />
					) : (
						<div className="flex flex-row">
							<Select
								className="basic-multi-select mr-2 w-full rounded"
								options={listAvailableRentals || []}
								styles={selectGlobalStyles}
								placeholder="Agrega una renta"
								onChange={option => {
									// setSerieId(option?.value || "");
								}}
							/>
							<Button variant="outline" onClick={() => {}} className="h-9">
								<PlusSmall />
							</Button>
						</div>
					)}
				</div>
			</div> */}

			<div className="relative border-t border-gray-200">
				<Transition
					show={status === StatusEnum.MODIFY_RENTAL}
					enter="transition-opacity duration-75"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-150"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="absolute left-1/2 top-6 rounded-full bg-white p-2 shadow-lg ring ring-gray-200">
						<Spinner />
					</div>
				</Transition>
				<dl>
					<div className="flex h-full flex-col p-6 pt-4">
						<DataTable
							ref={ref => setTableRef(ref)}
							columns={columns}
							dataset={currentUserRentalList}
							pageCount={1}
							pagination={{pageIndex: 0, pageSize: 1000}}
							loading={false}
							withDynamicPageSize
							rowHeight={53}
							showPagination={false}
						/>
					</div>
				</dl>
			</div>
		</>
	);
};

export default Rentals;
