import {useCallback, useEffect, useMemo, useState} from "react";
import {Spinner} from "../../../../../../components/primitives/icons";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../../../../../components/primitives/Table";
import {Slots, UserSubscription} from "../../../../../../data-access/subscriptions/subscriptions";
import {SLOTS_STATES} from "../../../../../../constants";
import {auth} from "../../../../../../firebase";
import {fetchDbUsers} from "../../../../../../data-access/fetch-db-users";
import {dbUser} from "../../../../../../_store/features/user-db/user-db-slice";

export interface SlotsTableProps {
	data?: UserSubscription["slots"];
	isLoading?: boolean;
	onSelectRow?: (row: UserSubscription) => void;
}

type BuddyUser = {id: string; name: string; email: string};

export default function SlotsTable({data, isLoading, onSelectRow}: SlotsTableProps) {
	const [loadingUsers, setloadingUsers] = useState(false);
	const [buddyUsersList, setBuddyUsersList] = useState<BuddyUser[]>([]);

	const fetchUsersById = useCallback(() => {
		if (!data?.length || data[0] === null) return;
		setloadingUsers(true);
		auth.currentUser?.getIdToken().then(token => {
			const filteredData = (data as Slots[]).filter(el => el !== null && el.buddy_id !== null);
			Promise.all(
				filteredData.map(slot =>
					fetchDbUsers({idToken: token, searchby: "id", searchvalue: slot!.buddy_id as string, page: 0, admin: false}),
				),
			)
				.then(res => {
					const userData = res.map(el => el.data.results[0] as dbUser);
					setBuddyUsersList(prev => {
						const users = userData.map(user => ({id: user.id, name: user.name, email: user.email}));
						return [...prev, ...users];
					});
				})
				.finally(() => setloadingUsers(false));
		});
	}, [data]);

	useEffect(() => {
		if (data) fetchUsersById();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const tableData = useMemo(() => (data as Slots[])?.filter(el => el !== null) ?? [], [data]);

	return (
		<Table className="h-40">
			<TableHeader>
				<TableRow>
					<TableHead>#</TableHead>
					<TableHead>Estado</TableHead>
					<TableHead>Nombre</TableHead>
					<TableHead>Email</TableHead>
					<TableHead>Id</TableHead>
				</TableRow>
			</TableHeader>
			<TableBody>
				{isLoading ? (
					<TableCell colSpan={6}>
						<Spinner className="mx-auto my-5 h-6 w-6 animate-spin" />
					</TableCell>
				) : !tableData?.length ? (
					<TableCell colSpan={6}>
						<span className="text-lg font-semibold text-amber-600">Usuario sin cupos disponibles</span>
					</TableCell>
				) : (
					tableData.map((slot, idx) => (
						<TableItem key={slot?.id ?? idx} slot={slot} buddyUsersList={buddyUsersList} loading={loadingUsers} idx={idx} />
					))
				)}
			</TableBody>
		</Table>
	);
}

const TableItem = ({slot, buddyUsersList, loading, idx}: {slot: Slots | null; idx: number; buddyUsersList: BuddyUser[]; loading?: boolean}) => {
	const slotCustomData = useMemo(() => {
		const status = slot?.time_lock ? "Bloqueado" : SLOTS_STATES.find(state => state.value === slot?.state)?.label ?? "";
		const user = buddyUsersList.find(user => user.id === slot?.buddy_id);

		return {status, userEmail: user?.email, userName: user?.name, userId: user?.id};
	}, [slot, buddyUsersList]);

	return (
		<TableRow className="odd:bg-accent/90">
			<TableCell>{idx + 1}</TableCell>
			<TableCell>{slotCustomData.status}</TableCell>
			<TableCell>{loading ? "Cargando.." : slotCustomData.userName || "-"}</TableCell>
			<TableCell>{loading ? "Cargando.." : slotCustomData.userEmail || "-"}</TableCell>
			<TableCell>{loading ? "Cargando.." : slotCustomData.userId || "-"}</TableCell>
		</TableRow>
	);
};
