import * as React from "react";
import {format} from "date-fns";
import {Matcher} from "react-day-picker";
import {Button} from "../../primitives/Button";
import {Popover, PopoverContent, PopoverTrigger} from "../../primitives/Popover/popover";
import {cn} from "../../../../utils/classNames";
import {Calendar as CalendarIcon} from "../../primitives/icons";
import {Calendar} from "../../primitives/Calendar/calendar";

export interface DayPickerProps extends React.HTMLAttributes<HTMLDivElement> {
	onDayChange: (selectedDay: Date) => void;
	disabled?: Matcher | Matcher[];
	selectedDay?: Date | undefined;
	color?: string;
	placeholder?: string;
	formatDate?: string;
	autoConfirm?: boolean;
}

export const DayPicker = React.forwardRef<HTMLDivElement, DayPickerProps>(function DayPicker(
	{
		className,
		disabled,
		onDayChange,
		selectedDay,
		color,
		placeholder = "Seleccione una fecha",
		formatDate = "LLL dd, y",
		autoConfirm = false,
	}: DayPickerProps,
	ref,
) {
	const [open, setOpen] = React.useState(false);
	const [chosenDay, setChosenDay] = React.useState(selectedDay);

	const handleConfirm = () => {
		if (chosenDay && typeof onDayChange === "function") {
			onDayChange(chosenDay);
			setOpen(false);
		}
	};

	const handleOpenChange = (open: boolean) => {
		if (chosenDay && typeof onDayChange === "function") {
			onDayChange(chosenDay);
		}
		setOpen(open);
	};

	React.useEffect(() => {
		if (chosenDay && typeof onDayChange === "function" && autoConfirm) {
			onDayChange(chosenDay);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoConfirm, chosenDay]);

	return (
		<div className={cn("grid gap-2", className)} ref={ref}>
			<Popover open={open} onOpenChange={o => handleOpenChange(o)} modal>
				<PopoverTrigger asChild>
					<Button
						id="date"
						variant="outline"
						className={cn(
							"m-w-[300px] mx-auto justify-start text-center font-normal dark:hover:bg-slate-500",
							!chosenDay && "text-muted-foreground",
						)}
						style={{color: !open ? color : "gray"}}
					>
						<CalendarIcon className="mr-2 h-4 w-4" />
						{chosenDay ? format(chosenDay, formatDate) : placeholder}
					</Button>
				</PopoverTrigger>

				<PopoverContent className="w-auto p-2" align="start">
					<Calendar
						showOutsideDays={false}
						mode="single"
						defaultMonth={new Date()}
						selected={chosenDay}
						onSelect={setChosenDay}
						disabled={disabled}
					/>
					<div className="flex items-center justify-center space-x-4">
						<Button onClick={handleConfirm} className="mt-2">
							Confirmar
						</Button>
					</div>
				</PopoverContent>
			</Popover>
		</div>
	);
});
