import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
	CreateUserSubscription,
	GetUserSubscriptionsParams,
	ListProductSubscriptionsParams,
	Subscription,
	createSubscription,
	getProductSubscriptions,
	getUserSubscription,
	updateSubscription,
} from "../../../data-access/subscriptions/subscriptions";
import {auth} from "../../../firebase";

export interface subscriptionInterface {
	results: Subscription[];
	loading: boolean;
	error: string;
	success: boolean;
	totalResults: number;
	pageSize: number;
}

const initialState: subscriptionInterface = {
	results: [],
	loading: false,
	error: "",
	success: false,
	pageSize: 0,
	totalResults: 0,
};

const createUserSubscription = createAsyncThunk("subscriptions/create", async (params: CreateUserSubscription, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await createSubscription(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem creating subscription");
	}
});

const editUserSubscription = createAsyncThunk("subscriptions/edit", async (params: CreateUserSubscription, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await updateSubscription(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem updating subscription");
	}
});

const getSubscriptions = createAsyncThunk("subscriptions/get-subscriptions", async (params: ListProductSubscriptionsParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getProductSubscriptions(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching subscriptions");
	}
});

const getAllUserSubscriptions = createAsyncThunk("subscriptions/get-user-subscriptions", async (params: GetUserSubscriptionsParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getUserSubscription(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue(error.response.data ?? "Problem fetching user subscriptions");
	}
});

const subscriptionSlice = createSlice({
	name: "subscriptions",
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers(builder) {
		builder
			.addCase(getSubscriptions.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(getSubscriptions.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...action.payload?.results!];
				state.success = true;
			})
			.addCase(getSubscriptions.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
	},
});

export const subscriptionActions = {
	...subscriptionSlice.actions,
	createUserSubscription,
	getSubscriptions,
	getAllUserSubscriptions,
	editUserSubscription,
};

export default subscriptionSlice;
