import {format} from "date-fns";
import {Button} from "../../../../../../components/primitives/Button";
import {CancelCircleFilled, CheckCircleFilled, Pencil, Spinner} from "../../../../../../components/primitives/icons";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../../../../../components/primitives/Table";
import Tooltip from "../../../../../../components/primitives/ToolTip-toDeprecate";
import {UserSubscription} from "../../../../../../data-access/subscriptions/subscriptions";

export interface SubscriptionsTableProps {
	data?: UserSubscription[];
	isLoading?: boolean;
	onSelectRow?: (row: UserSubscription) => void;
}

export default function SubscriptionsTable({data, isLoading, onSelectRow}: SubscriptionsTableProps) {
	return (
		<Table className="h-40">
			<TableHeader>
				<TableRow>
					<TableHead>Activa</TableHead>
					<TableHead>Suscripción</TableHead>
					<TableHead>Autorrenovación</TableHead>
					<TableHead>Fecha de terminación</TableHead>
					<TableHead>Plataforma</TableHead>
					<TableHead>Controles</TableHead>
				</TableRow>
			</TableHeader>
			<TableBody>
				{isLoading ? (
					<TableCell colSpan={6}>
						<Spinner className="mx-auto my-5 h-6 w-6 animate-spin" />
					</TableCell>
				) : !data?.length ? (
					<TableCell colSpan={6}>
						<span className="text-lg font-semibold text-amber-600">Usuario sin suscripción</span>
					</TableCell>
				) : (
					data?.map((subscription, idx) => {
						const unformattedDate = new Date(subscription.termination_date);
						const date = format(unformattedDate, "yyyy/MM/dd");

						return (
							<TableRow className="odd:bg-accent/40">
								<TableCell>
									<div className="flex w-full items-center">
										<span
											className={
												"relative inline-block px-3 font-semibold leading-tight" +
												(!subscription.active ? " text-red-700" : " text-green-700")
											}
										>
											<Tooltip
												text={!subscription.active ? "Desactivada" : "Activada"}
												placement={idx === 0 ? "bottom" : "top"}
											>
												{!subscription.active ? <CancelCircleFilled /> : <CheckCircleFilled />}
											</Tooltip>
										</span>
									</div>
								</TableCell>
								<TableCell>{subscription.name}</TableCell>
								<TableCell>
									<div className="flex w-full items-center">
										<span
											className={
												"relative inline-block px-3 font-semibold leading-tight" +
												(!subscription.subscription_renewal ? " text-red-700" : " text-green-700")
											}
										>
											<Tooltip
												text={subscription.subscription_renewal ? "Autorrenovación" : "Sin Autorrenovación"}
												placement={idx === 0 ? "bottom" : "top"}
											>
												{!subscription.subscription_renewal ? <CancelCircleFilled /> : <CheckCircleFilled />}
											</Tooltip>
										</span>
									</div>
								</TableCell>
								<TableCell>{date || "Sin definir"}</TableCell>
								<TableCell className="capitalize">
									{subscription.prospay_subscriptionid ? "Prospay" : subscription.last_payment_platform}
								</TableCell>
								<TableCell>
									<Button size={"sm"} variant={"outline"} onClick={() => onSelectRow?.(subscription)} disabled={false}>
										<Pencil className="h-4 w-4" />
									</Button>
								</TableCell>
							</TableRow>
						);
					})
				)}
			</TableBody>
		</Table>
	);
}
